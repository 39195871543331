<template>
  <div class="dashboard-all">
    <div class="dataUC">
      <div class="dataUC__header">
        <div class="dataUC__header-left">
          <!-- <div class="dataUC__header__pagination" v-if="showMenu">
                  <list-pagination
                  :listPagesCount="tableRules.lengthMenu"
                  :pageSize="tableRules.limit"
                  @changePageFromListPagination="changePageFromListPagination"
                  >
                  </list-pagination>
              </div> -->
          <div class="dataUC__header__showCol">
            <list-columns-name
              :listColumns="dataTable.columnsOptionHide"
              :listColHide="listColHide"
              :countCloseListColumnsName="countCloseListColumnsName"
              :idItem="props?.item?.id"
              type="table-screen"
              @showColumn="showColumn"
              @hideColumn="hideColumn"
              @closeListFilter="closeListFilter"
              @closeWarningData="closeWarningData"
            ></list-columns-name>
          </div>
        </div>
        <div class="dataUC__header-mid">
          <div class="dataUC__header__filter">
            <list-columns-filter
              :listColumns="dataTable.selectColumnsToFilter"
              :countCloseListFilter="countCloseListFilter"
              :nameFilterURL="nameFilterURL"
              @nameColumnToFilter="nameColumnToFilterPlacehoder"
              @closeListColumnsName="closeListColumnsName"
              @closeWarningData="closeWarningData"
              :key="keyListColumnsFilter"
            ></list-columns-filter>
            <div
              class="dataUC__header__filter-icon"
              style="background-color: #fff"
            >
              <span class="icon is-small is-left">
                <svg
                  class="svg-inline--fa fa-search"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    class=""
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </span>
              <el-input
                v-model="inputSearch"
                class="w-100"
                :placeholder="$t('t_input_column_search_value', { msg: nameColumnToFilter })"
                @change="filterData"
              />
            </div>
          </div>
        </div>
        <div
          v-if="props.checkShowDown"
          class="dataUC__header-right"
          :class="{
            hideCardHeader: !props.is_hiddenCardHeader,
          }"
        >
          <div>
            <el-tooltip
              class="box-item"
              effect="light"
              content="Tải xuống"
              placement="top"
            >
              <button
                v-if="isClickDownload"
                @click="downloadCard"
                type="button"
                class="button-download"
              >
                Excel
                <span class="icon is-small"
                  ><svg
                    class="svg-inline--fa fa-file-excel"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="file-excel"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                    ></path>
                  </svg>
                </span>
              </button>
            </el-tooltip>
            <el-button v-if="isBtnLoading" type="button" :loading="loadingBtn">
              {{$t('t_loading')}}...
            </el-button>
          </div>
        </div>
      </div>
      <div class="dataUC__body">
        <table-screen-component
          :item="dataCarditem"
          :itemData="dataTable.result"
          :isLoading="loadingData"
          :configTable="dataCarditem.table?.format"
          :configPaging="dataCarditem.table?.isPaging"
          :listColumnsStatus="listColumnsStatus"
          type="dashboard"
          :tableRules="props.itemTableRules"
          @getDataTableWhenSort="fn_tableSortChange"
          @showDialogDashboardPopupFromTableScreenComponent="
            showDialogDashboardPopupFromTableScreenComponent
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted, reactive, watch } from "vue";
import MethodService from "../../service/MethodService";
import DataForm from "./dataTableScreen";
import { useRoute } from "vue-router";
// import ListPagination from "../ListPagination.vue";
import ListColumnsName from "../ListColumnsName.vue";
import ListColumnsFilter from "../ListColumnsFilter";
import TableScreenComponent from "./TableScreenComponent.vue";
import { Card_item } from "cem-probe-api";
const emit = defineEmits([]);
const props = defineProps({
  item: Card_item,
  itemData: null,
  checkShowDown: Boolean,
  itemTableRules: Object,
  is_hiddenCardHeader: Boolean,
  showEdit: Boolean,
});
const loadingData = ref(false);
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const route = useRoute();
const showMenu = ref(false);
const dataTable = reactive({
  data: [],
  result: [],
  columnsOptionHide: [],
  selectColumnsToFilter: [],
  total: 0,
  totalPages: 0,
});
const idCardItem = ref();
const dataCarditem = ref({});
const listColHide = ref([]);
const countCloseListColumnsName = ref(0);
const countCloseListFilter = ref(0);
const countCloseWarningData = ref(0);
const nameFilterURL = ref("");
const nameColumnToFilter = ref("");
const valueColumnToFilter = ref("");
const typeColumnToFilter = ref("datepicker");
const inputSearch = ref("");
const keyListColumnsFilter = ref(0);
const listColumnsStatus = ref([]);
const tableRulesOffset = ref(0);
const isClickDownload = ref(true);
const loadingBtn = ref(false);
const isBtnLoading = ref(false);
const showDialogDashboardPopupFromTableScreenComponent = (id, y, value) => {
  emit(
    "showDialogDashboardPopup",
    id,
    MethodService.renderLinkChart(
      null,
      y,
      value,
      localStorage.getItem("urlOld")
        ? localStorage.getItem("urlOld")
        : location.href
    )
  );
};
const changeDataSource = (data) => {
  dataTable.data = [];
  dataTable.result = data;
  dataTable.total = data?.total ?? 0;
  dataTable.totalPages = Math.ceil(dataTable.total / tableRules.limit);

  data.data?.forEach((item) => {
    let obj = {};
    item.forEach((item1, index1) => {
      if (!data.columns[index1] || !data.columns[index1].code) return;
      obj[data.columns[index1].code] = item1;
    });
    dataTable.data.push(obj);
  });
  if (props.itemTableRules.offset || props.itemTableRules.offset === 0)
    tableRulesOffset.value = props.itemTableRules.offset;
};

const setCardItem = () => {
  showMenu.value = dataCarditem.value.table?.isPaging ?? false;

  idCardItem.value = dataCarditem.value.id;
  dataTable.selectColumnsToFilter = [];
  dataTable.columnsOptionHide = [];
  listColHide.value = [];
  dataCarditem.value.table.format.forEach((item) => {
    if (item.filter.is_allowed)
      dataTable.selectColumnsToFilter.push({
        value: item?.code,
        label: item.title_name,
        type: item.filter?.input_type ? item.filter.input_type : "input",
      });
    if (item.code && item.visible) {
      dataTable.columnsOptionHide.push({
        value: item.code,
        label: item.title_name,
        field_name: item.field_name,
      });
      if (item.visible && !item?.defaultHide)
        listColumnsStatus.value.push(item.field_name);
      if (item?.defaultHide) listColHide.value.push(item?.code);
    }
  });
  if (props.itemData) changeDataSource(props.itemData);
};

const downloadCard = async () => {
  emit("download", props.item.title);
};

const showColumn = (nameColumn) => {
  listColumnsStatus.value.push(nameColumn);
  emit("columnsOnScreen", listColumnsStatus.value);
};

const hideColumn = (nameColumn) => {
  listColumnsStatus.value = listColumnsStatus.value.filter(
    (item) => item !== nameColumn
  );
  emit("columnsOnScreen", listColumnsStatus.value);
};

const filterData = async () => {
  const keySearch =
    valueColumnToFilter.value ?? dataTable.selectColumnsToFilter[0].value;

  tableRules.filterKey = keySearch;
  tableRules.filterValue = inputSearch.value;
  emit("filterData", tableRules.sort, keySearch, inputSearch.value, "filter");
};

const closeListFilter = () => {
  countCloseListFilter.value += 1;
};
const closeWarningData = () => {
  countCloseWarningData.value += 1;
};

const nameColumnToFilterPlacehoder = (nameColumn, valueColumn, typeColumn) => {
  nameColumnToFilter.value = nameColumn;
  valueColumnToFilter.value = valueColumn;
  typeColumnToFilter.value = typeColumn ? typeColumn : "datepicker";
  if (!tableRules.filterKey) inputSearch.value = "";
};

const closeListColumnsName = () => {
  countCloseListColumnsName.value += 1;
};

const fn_tableSortChange = async (column, order) => {
  dataTable.columnsOptionHide.forEach((item) => {
    if (item.value === column.prop) column.prop = item.field_name;
  });
  tableRules.sort = order;
  emit(
    "filterData",
    tableRules.sort,
    tableRules.filterKey,
    tableRules.filterValue,
    "sort"
  );
};

// const changePageFromListPagination = (pageSize) => {
//   fn_tableSizeChange(pageSize);
// };

// phân trang
// const fn_tableSizeChange = (limit) => {
//   tableRules.limit = limit;
//   fn_tableChangeOffset(tableRules.page);
// };
// const fn_tableChangeOffset = async (page) => {
//   tableRules.page = page;
//   tableRules.offset = (tableRules.page - 1) * tableRules.limit;
//   await getDataSource(idCardItem.value);
// };

const getInfoURL = () => {
  if (route.query.$sort) {
    let strSort = route.query.$sort;
    let objSort = {};
    if (strSort.includes("-")) {
      let keySort = strSort.toString().replace("-", "");
      objSort[keySort] = -1;
      tableRules.sort = objSort;
    } else {
      objSort[strSort] = 1;
      tableRules.sort = objSort;
    }
    tableRules.defaultSort[0] = tableRules.sort;
    route.query.$sort.indexOf("-") !== -1
      ? (tableRules.defaultSort[1] = "descending")
      : (tableRules.defaultSort[1] = "ascending");
  }
  if (route.query.$colfilter) {
    nameFilterURL.value = route.query.$colfilter;
    let strFilterURL = route.query.$colfilter;
    let index = strFilterURL.toString().indexOf(":");
    tableRules.filterKey = strFilterURL.toString().slice(0, index);
    tableRules.filterValue = strFilterURL.toString().slice(index + 1);
    inputSearch.value = tableRules.filterValue;
  }
  if (route.query.$limit) {
    tableRules.limit = Number(route.query.$limit);
  }
};

watch(
  () => props.item,
  (newData) => {
    dataCarditem.value = newData;
    setCardItem();
  }
);

watch(
  () => props.itemData,
  (newData) => {
    dataCarditem.value = props.item;
    if (!props.itemData?.data) dataCarditem.value = {};
    changeDataSource(newData);
  }
);

watch(
  () => props.itemTableRules,
  (newData) => {
    if (newData.offset) {
      tableRulesOffset.value = props.itemTableRules.offset;
    }
  }
);

onMounted(async () => {
  getInfoURL();
  keyListColumnsFilter.value += 1;
  dataCarditem.value = props.item;
  setCardItem();
});
</script>
<link
  type="scss"
  src="../../assets/scss/components/_table-screen-dashboard.scss"
/>
