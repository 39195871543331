<template>
  <el-dialog
    v-model="centerDialogVisible"
    :title="
      props.typeShowCardItemConfig == 'create'
        ? `${$t('t-add-chart')} ${props.cardTitleToCardItemsConfig}`
        : `${$t('t-options-chart')} ${titleCardItem}`
    "
    width="90%"
    :close-on-click-modal="false"
    :before-close="beforeCloseDialog"
    align-center
  >
    <div class="d-flex justify-content-around mb-3">
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center">
          <i
            class="ri-check-line span-step"
            :class="{ passStep: stepNumber > 1 }"
            v-if="stepNumber > 1"
          ></i>
          <span class="span-step" :class="{ passStep: stepNumber == 1 }" v-else
            >1</span
          >
        </div>
        <div class="">{{ $t("t_select_chart_type") }}</div>
      </div>
      <div class="line"></div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center">
          <i
            class="ri-check-line span-step"
            :class="{ passStep: stepNumber > 2 }"
            v-if="stepNumber > 2"
          ></i>
          <span class="span-step" :class="{ passStep: stepNumber == 2 }" v-else
            >2</span
          >
        </div>
        <div
          v-if="
            DataCardItems.value.type === 'table' ||
            DataCardItems.value.type === 'table-screen'
          "
        >
          {{ $t("t_config_table_column") }}
        </div>
        <div v-else>{{ $t("t_interface_config") }}</div>
      </div>
      <div class="line"></div>
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex align-items-center">
          <i
            class="ri-check-line span-step"
            :class="{ passStep: stepNumber > 3 }"
            v-if="stepNumber > 3"
          ></i>
          <span class="span-step" :class="{ passStep: stepNumber > 2 }" v-else
            >3</span
          >
        </div>
        <div class="">{{ $t("t_data_config") }}</div>
      </div>
    </div>
    <el-form
      ref="ruleFormRef"
      :model="DataCardItems.value"
      :rules="rulesForm"
      label-width="150px"
      label-position="top"
      class="form-card-items"
    >
      <div v-if="stepNumber == 1">
        <el-form-item>
          <div
            class="select-card-item"
            v-if="DataCardItems.value.type == 'copy'"
          >
            <div class="select-card-item__select">
              <div class="select-card-item__select-item item-relative">
                <span class="label-requied-item" slots="label">*</span>
                <el-form-item :label="$t('t_select_dashboard')">
                  <el-select
                    filterable
                    v-model="nameDashboard"
                    :placeholder="$t('t_select_dashboard')"
                    @change="getListDashboard"
                  >
                    <el-option
                      v-for="item in DataForm.listDashboards"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="select-card-item__select-item item-relative"
                v-show="isSelectMenuPhanCap"
              >
                <span class="label-requied-item" slots="label">*</span>
                <el-form-item :label="$t('t_select_menu')">
                  <el-select
                    filterable
                    v-model="nameMenuSelect"
                    :placeholder="$t('t_select_menu')"
                    @change="getTypeForDashboard"
                  >
                    <el-option
                      v-for="item in storeListMenu.menuPhanCap"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="select-card-item__select-item item-relative"
                v-show="typeDashboard === 'service'"
              >
                <span class="label-requied-item" slots="label">*</span>
                <el-form-item :label="$t('t_select_service_dashboard')">
                  <el-select
                    filterable
                    v-model="nameDashboardService"
                    :placeholder="$t('t_select_service_dashboard')"
                    @change="getListDashboardService"
                    :disabled="disableSelectListService"
                  >
                    <el-option
                      v-for="item in listService"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="select-card-item__select-item item-relative"
                v-show="typeDashboard === 'dynamic'"
              >
                <span class="label-requied-item" slots="label">*</span>
                <el-form-item :label="$t('t_select_dynamic_dashboard')">
                  <el-select
                    filterable
                    v-model="nameDashboardDynamic"
                    :placeholder="$t('t_select_dynamic_dashboard')"
                    @change="getListDashboardDynamic"
                    :disabled="disableSelectListDynamic"
                  >
                    <el-option
                      v-for="item in listDynamic"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
              <div class="select-card-item__select-item item-relative">
                <span class="label-requied-item" slots="label">*</span>
                <el-form-item :label="$t('t_select_card')">
                  <el-select
                    filterable
                    v-model="nameCard"
                    :placeholder="$t('t_select_card')"
                    @change="getListCardItems"
                  >
                    <el-option
                      v-for="item in listCardItems"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="select-card-item__item">
              <el-scrollbar height="286px">
                <div v-if="loadingGetListCardItems" class="card-item__status">
                  <i
                    style="font-size: 30px; opacity: 0.5; color: #0f6a33"
                    class="bx bx-loader-alt loading-icon"
                  ></i>
                  <p>{{ $t("t-loading-get-data") }}</p>
                </div>
                <div v-else>
                  <div v-if="cardItemsBlock.length > 0" class="card-item">
                    <div
                      class="card-item__block"
                      v-for="item in cardItemsBlock"
                      :key="item.id"
                      @click="clickCardItemBlock(item)"
                      :id="item.id"
                    >
                      <div>
                        <img
                          :src="linkImg(item.type)"
                          height="50"
                          width="50"
                          alt=""
                        />
                      </div>
                      <div class="card-item__box">
                        <div class="card-item__title">{{ item.title }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card-item__status" v-else>
                    <i
                      style="font-size: 30px; opacity: 0.5; color: #e0ba00"
                      class="ri-information-line"
                    ></i>
                    <p>{{ $t("t-no-card-item") }}</p>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
          <el-radio-group v-model="DataCardItems.value.type" v-else>
            <el-scrollbar height="380px">
              <b-row class="chart-group w-100">
                <b-col md="4" class="text-center">
                  <el-radio-button label="chart-bar-vert-single">
                    <img
                      src="../../assets/images/chart/chart-bar-vert-single.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-bar-vert-single</span>
                  </el-radio-button>
                </b-col>
                <b-col md="4" class="text-center">
                  <el-radio-button label="chart-bar-vert-multi">
                    <img
                      src="../../assets/images/chart/chart-bar-vert-multi.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-bar-vert-multi</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-bar-hori-single">
                    <img
                      src="../../assets/images/chart/chart-bar-hori-single.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-bar-hori-single</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-bar-hori-multi">
                    <img
                      src="../../assets/images/chart/chart-bar-hori-multi.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-bar-hori-multi</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-stack-bar-vert">
                    <img
                      src="../../assets/images/chart/chart-stack-bar-vert.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-stack-bar-vert</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-stack-bar-hori">
                    <img
                      src="../../assets/images/chart/chart-stack-bar-hori.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-stack-bar-hori</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-line-single">
                    <img
                      src="../../assets/images/chart/chart-line-single.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-line-single</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-line-multi">
                    <img
                      src="../../assets/images/chart/chart-line-multi.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-line-multi</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-line-area-single">
                    <img
                      src="../../assets/images/chart/chart-line-area-single.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-line-area-single</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-line-area-multi">
                    <img
                      src="../../assets/images/chart/chart-line-area-multi.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-line-area-multi</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-pie">
                    <img
                      src="../../assets/images/chart/chart-pie.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-pie</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="chart-radar">
                    <img
                      src="../../assets/images/chart/chart-radar.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>chart-radar</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="table">
                    <img
                      src="../../assets/images/chart/table.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>table</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="group-text">
                    <img
                      src="../../assets/images/chart/group-text.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>group-text</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="err-network-2segments">
                    <img
                      src="../../assets/images/chart/err-network-2segments.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>err-network-2segments</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="err-network-3segments">
                    <img
                      src="../../assets/images/chart/err-network-3segments.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>err-network-3segments</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="stats-network-multi-serv">
                    <img
                      src="../../assets/images/chart/stats-network-multi-serv.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>stats-network-multi-serv</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="time-line">
                    <img
                      src="../../assets/images/chart/time-line.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>time-line</span>
                  </el-radio-button></b-col
                >
                <b-col md="4" class="text-center"
                  ><el-radio-button label="table-screen">
                    <img
                      src="../../assets/images/chart/table-screen.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>table-screen</span>
                  </el-radio-button></b-col
                >

                <b-col md="4" class="text-center"
                  ><el-radio-button label="copy">
                    <img
                      src="../../assets/images/chart/copy.svg"
                      height="50"
                      width="50"
                      alt=""
                    />
                    <span>{{ $t("toastr_copy_card_item") }}</span>
                  </el-radio-button></b-col
                >
              </b-row>
            </el-scrollbar>
          </el-radio-group>
        </el-form-item>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            v-if="DataCardItems.value.type == 'copy'"
            @click="DataCardItems.value.type = ''"
          >
            {{ $t("t-back") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            v-if="DataCardItems.value.type == 'copy'"
            :disabled="
              cardItemsBlock.length == 0 || countClickCardItemBlock == 0
            "
            @click="copyCardItemNext"
          >
            {{ $t("t-copy-card-item") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            @click="stepNumber += 1"
            :disabled="!DataCardItems.value.type"
            v-else
          >
            {{ $t("t-next") }}
          </button>
        </div>
      </div>

      <div v-if="stepNumber == 2">
        <div v-show="props.typeShowCardItemConfig === 'update'">
          <render-icon-chart
            :infoChartType="DataCardItems.value.type"
          ></render-icon-chart>
        </div>
        <el-form-item :label="`${$t('t-title-card-item')}`" prop="title">
          <el-input
            type="text"
            :placeholder="`${$t('t-title-card-item')}`"
            v-model="DataCardItems.value.title"
            @change="
              DataCardItems.value.title = DataCardItems.value.title.trim()
            "
          />
        </el-form-item>

        <!-- collap -->
        <el-collapse
          v-model="activeNames"
          class="config-card-item"
          v-if="
            DataCardItems.value.type?.includes('table') ||
            DataCardItems.value.type?.includes('table-screen') ||
            DataCardItems.value.type?.includes('chart') ||
            DataCardItems.value.type?.includes('network') ||
            DataCardItems.value.type?.includes('copy')
          "
        >
          <el-collapse-item :title="$t('t_advanced_config')" name="0">
            <div class="optionCardItem">
              <div class="optionCardItem__header">
                <i class="ri-price-tag-3-fill"></i>
                {{ $t("t-options-title") }}
              </div>
              <div class="optionCardItem__body">
                <div class="position-relative">
                  <b-row>
                    <b-col md="4">
                      <el-form-item :label="$t('t-hide-header')" class="d-flex">
                        <el-switch
                          v-model="DataCardItems.value.general.header.is_hidden"
                        />
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item
                        :label="`${$t('t-color-title')}`"
                        class="d-flex"
                      >
                        <el-color-picker
                          v-model="DataCardItems.value.general.header.color"
                        />
                      </el-form-item>
                    </b-col>
                    <b-col md="4">
                      <el-form-item
                        :label="`${$t('t-background-color-title')}`"
                        class="d-flex"
                      >
                        <el-color-picker
                          v-model="
                            DataCardItems.value.general.header.backgroundColor
                          "
                        />
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div
                    class="tooltip-color"
                    @click="showDialogTooltipColors = !showDialogTooltipColors"
                  >
                    <i class="ri-question-line"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="optionCardItem">
              <div class="optionCardItem__header">
                <i class="ri-calendar-event-fill"></i>
                {{ $t("t-options-offset-date") }}
              </div>
              <div class="optionCardItem__body">
                <b-row>
                  <b-col md="6">
                    <el-form-item
                      :label="`${$t('t-prev-date-data')}`"
                      class="d-flex"
                    >
                      <el-input-number
                        type="number"
                        controls-position="right"
                        :placeholder="`${$t('t-place-prev-date-data')}`"
                        v-model="DataCardItems.value.settings.add_start_days"
                      />
                    </el-form-item>
                  </b-col>
                  <b-col md="6">
                    <el-form-item
                      :label="`${$t('t-next-date-data')}`"
                      class="d-flex"
                    >
                      <el-input-number
                        type="number"
                        controls-position="right"
                        :placeholder="`${$t('t-place-next-date-data')}`"
                        v-model="DataCardItems.value.settings.add_end_days"
                      />
                    </el-form-item>
                  </b-col>
                </b-row>
              </div>
            </div>

            <el-form-item v-if="DataCardItems.value.type.includes('chart')">
              <div class="optionCardItem">
                <div class="optionCardItem__header">
                  <i class="ri-pie-chart-2-fill"></i>
                  {{ $t("t-options-chart") }}
                </div>
                <div class="optionCardItem__body">
                  <div class="title-opitons">
                    <p>{{ $t("t-title-card-item") }}</p>
                    <el-form-item
                      :label="$t('t-content')"
                      class="title-options__title"
                    >
                      <el-input
                        type="text"
                        :placeholder="`${$t('t-title-card-item')}`"
                        v-model="DataCardItems.value.chart.title.text"
                      />
                    </el-form-item>
                    <div class="position-relative">
                      <b-row>
                        <b-col md="4">
                          <el-form-item
                            :label="$t('t-display-title')"
                            class="d-flex"
                          >
                            <el-switch
                              v-model="DataCardItems.value.chart.title.show"
                            />
                          </el-form-item>
                        </b-col>
                        <b-col md="4">
                          <el-form-item :label="$t('t-table-config-font_size')" class="d-flex">
                            <el-input-number :min="1" controls-position="right" v-model="DataCardItems.value.chart.title.textStyle
                              .fontSize
                              " />
                          </el-form-item>
                        </b-col>
                        <b-col md="4">
                          <el-form-item :label="$t('t_text_color')" class="d-flex">
                            <el-color-picker v-model="DataCardItems.value.chart.title.textStyle.color
                              " />
                          </el-form-item>
                        </b-col>
                      </b-row>
                      <div
                        class="tooltip-color"
                        @click="
                          showDialogTooltipColors = !showDialogTooltipColors
                        "
                      >
                        <i class="ri-question-line"></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="
                    DataCardItems.value.type.includes('chart-pie') ||
                    DataCardItems.value.type.includes('chart-radar')
                  " class="legend-options">
                    <p>{{ $t("t-legend") }}</p>
                    <div class="legend-options__items">
                      <div class="legend-options__item">
                        <el-form-item
                          :label="`${$t('t-type-legend')}`"
                          class="label-option"
                        >
                          <el-select
                            v-model="DataCardItems.value.chart.legend.orient"
                            :placeholder="`${$t('t-place-legend')}`"
                          >
                            <el-option
                              v-for="item in DataForm.formatLegend"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="legend-options__item">
                        <el-form-item :label="`${$t('t-position-legend-horizontal')}`" class="label-option">
                          <el-select v-model="DataCardItems.value.chart.legend.align.horizontal
                            " placeholder="$t('t-select')" >
                            <el-option v-for="item in DataForm.horizontalLegend" :key="item.value" :label="item.label"
                              :value="item.value" />
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="legend-options__item">
                        <el-form-item :label="`${$t('t-position-legend-vertical')}`" class="label-option">
                          <el-select v-model="DataCardItems.value.chart.legend.align.vertical
                            " placeholder="$t('t-select')">
                            <el-option v-for="item in DataForm.verticalLegend" :key="item.value" :label="item.label"
                              :value="item.value" />
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="grid-options">
                    <p>{{ $t("t-grid") }}</p>
                    <div class="grid-options__items">
                      <div class="grid-options__item">
                        <el-form-item
                          :label="`${$t('t-height-chart')}`"
                          class="d-flex"
                        >
                          <div class="d-flex">
                            <div class="grid-options__input">
                              <el-input-number
                                type="number"
                                controls-position="right"
                                :placeholder="`${$t('t-min-height-card-item')}`"
                                v-model="DataCardItems.value.chart.y_axis.min"
                              />
                            </div>
                            <div class="grid-options__input">
                              <el-input-number
                                type="number"
                                controls-position="right"
                                :placeholder="`${$t('t-max-height-card-item')}`"
                                v-model="DataCardItems.value.chart.y_axis.max"
                              />
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <div class="grid-options__item">
                        <el-form-item
                          :label="`${$t('t-rotate-label-chart')}`"
                          class="d-flex"
                        >
                          <el-input-number
                            type="number"
                            :min="-90"
                            :max="90"
                            controls-position="right"
                            :placeholder="`${$t('t-place-rotate-label-chart')}`"
                            v-model="
                              DataCardItems.value.chart.y_axis.axisLabel.rotate
                            "
                          />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      DataCardItems.value.type.includes('chart-line-single') ||
                      DataCardItems.value.type.includes(
                        'chart-line-area-single'
                      ) ||
                      DataCardItems.value.type.includes('chart-line-multi') ||
                      DataCardItems.value.type.includes('chart-line-area-multi')
                    "
                    class="title-opitons"
                  >
                    <p>Cảnh báo</p>
                    <el-form-item
                      :label="`${$t('t-service')}`"
                      class="title-options__title"
                    >
                      <el-select
                        filterable
                        v-model="serviceChart"
                        :placeholder="`${$t('t-select-service')}`"
                        @change="getIdServiceChart"
                        class="service-card-items"
                        clearable
                      >
                        <el-option
                          v-for="item in listService"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      :label="`${$t('t-table-config')}`"
                      class="title-options__title"
                    >
                      <el-select
                        filterable
                        v-model="
                          DataCardItems.value.chart.alert.table_config_id
                        "
                        :placeholder="`${$t('t-select-table-config')}`"
                      >
                        <el-option
                          v-for="item in listTableConfigChart"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label="SQL"
                      class="title-options__title"
                      prop="chart.alert.sql"
                    >
                      <el-input
                        :placeholder="`${$t('t-place-sql')}`"
                        v-model="DataCardItems.value.chart.alert.sql"
                        :rows="5"
                        type="textarea"
                        :input-style="{
                          wordBreak: 'break-word',
                        }"
                      />
                    </el-form-item>
                    <el-form-item
                      :label="`${$t('t-sql-table-formats')}`"
                      class="title-options__title"
                      prop="chart.alert.sql_table_formats"
                    >
                      <el-input
                        :placeholder="`${$t('t-place-sql-formats')}`"
                        v-model="
                          DataCardItems.value.chart.alert.sql_table_formats
                        "
                        :rows="5"
                        type="textarea"
                        :input-style="{
                          wordBreak: 'break-word',
                        }"
                      />
                    </el-form-item>
                  </div>
                  <div class="position-relative">
                    <color-gradient-bar-chart
                      :colorConfigIn="colorConfigIn"
                      :typeChart="DataCardItems.value.type"
                      :isMultiColors="
                        DataCardItems.value.chart
                          .multi_color_for_single_bar_chart
                      "
                      :proplistDashboardPopupFromCardItemConfig="
                        dropListDashboardPopup
                      "
                      @colorConfigOut="colorConfigOut"
                      @multiColors="multiColors"
                      @updateListDashboardPopup="updateListDashboardPopup"
                    >
                    </color-gradient-bar-chart>
                    <div
                      class="tooltip-color"
                      @click="
                        showDialogTooltipColors = !showDialogTooltipColors
                      "
                    >
                      <i class="ri-question-line"></i>
                    </div>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <el-form-item
                        :label="`${$t('t-smooth-line')}`"
                        class="d-flex"
                      >
                        <el-switch v-model="DataCardItems.value.chart.smooth" />
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item
                        :label="`${$t('t-border-radius-conner')}`"
                        class="label-option"
                        prop="chart.itemStyle.borderRadius"
                      >
                        <el-select
                          v-model="DataCardItems.value.chart.borderRadiusType"
                          :placeholder="$t('t-select-border-radius-type')"
                        >
                          <el-option
                            v-for="item in DataForm.typeBorder"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col
                      md="12"
                      v-show="DataCardItems.value.type.includes('chart')"
                    >
                      <el-form-item
                        :label="`${$t('t-link-chart')}`"
                        class="d-flex"
                      >
                        <el-input
                          v-model="DataCardItems.value.chart.chart_link"
                          :placeholder="`${$t('t-placeholder-link-chart')}`"
                          clearable
                        />
                      </el-form-item>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              v-if="
                DataCardItems.value.type.includes('table') ||
                DataCardItems.value.type.includes('table-screen')
              "
            >
              <div class="optionCardItem">
                <div class="optionCardItem__header">
                  <i class="ri-table-fill"></i>
                  {{ $t("t-options-table") }}
                </div>
                <div class="optionCardItem__body">
                  <el-checkbox
                    v-model="DataCardItems.value.table.hasNo"
                    :label="`${$t('t-show-No-table')}`"
                    size="large"
                  />
                  <el-checkbox
                    v-model="DataCardItems.value.table.isPaging"
                    :label="`${$t('t-pagination-table')}`"
                    size="large"
                  />
                  <p>{{ $t("t-info-format-columns") }}</p>

                  <div class="d-flex justify-content-center">
                    <div class="render-table">
                      <div
                        v-for="(item, index) in dataTableConfigRender"
                        :key="item.id"
                        class="d-flex"
                      >
                        <table-columns-config
                          :optionColumns="item"
                          :indexColumns="index"
                          :key="keyTableConfig"
                          @editColums="editColums"
                          @deleteColums="deleteColums"
                        ></table-columns-config>
                      </div>
                    </div>

                    <div
                      class="btn-add-config-table"
                      @click="showModalTableColumnsConfig"
                    >
                      <i class="ri-add-circle-line"></i>
                    </div>
                  </div>

                  <!-- dialog hiển thị cấu hình từng cột table -->
                  <el-dialog
                    v-model="showTableColumnsConfig"
                    :title="$t('t-bar-interface-config')"
                    width="90%"
                    :before-close="beforeCloseDialogTableConfig"
                    :close-on-click-modal="false"
                    align-center
                    custom-class="table-format-cell"
                  >
                    <el-row :gutter="40" class="table-format">
                      <el-col :md="10">
                        <el-form-item
                          :label="`${$t('t-table-config-code')}`"
                          class="d-flex"
                        >
                          <span
                            v-show="
                              formatTableColumnsValue.value.filter.is_allowed
                            "
                            class="label-requied"
                            slots="label"
                            >*</span
                          >
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.code"
                            :placeholder="`${$t('t-place-table-config-code')}`"
                            @change="setValueCode()"
                          />
                        </el-form-item>

                        <el-form-item
                          :label="`${$t('t-table-config-field_name')}`"
                          class="d-flex"
                        >
                          <span class="label-requied" slots="label">*</span>
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.field_name"
                            :placeholder="`${$t(
                              't-place-table-config-field_name'
                            )}`"
                            clearable
                            @change="
                              formatTableColumnsValue.value.field_name =
                                MethodService.trimSpace(
                                  formatTableColumnsValue.value.field_name
                                )
                            "
                          />
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t-table-config-title_name')}`"
                          class="d-flex"
                        >
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.title_name"
                            :placeholder="`${$t(
                              't-place-table-config-title_name'
                            )}`"
                            clearable
                            @change="
                              formatTableColumnsValue.value.title_name =
                                MethodService.trimSpace(
                                  formatTableColumnsValue.value.title_name
                                )
                            "
                          />
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t-table-config-width')}`"
                          class="d-flex"
                        >
                          <el-input-number
                            v-model="formatTableColumnsValue.value.width"
                            :placeholder="`${$t('t-place-table-config-width')}`"
                            :min="1"
                            controls-position="right"
                          >
                          </el-input-number>
                          <span class="px-style">px</span>
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t-table-config-font_size')}`"
                          class="d-flex"
                        >
                          <el-input-number
                            v-model="formatTableColumnsValue.value.font_size"
                            :placeholder="`${$t(
                              't-place-table-config-font_size'
                            )}`"
                            :min="1"
                            controls-position="right"
                          >
                          </el-input-number>
                          <span class="px-style">px</span>
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t-table-config-align')}`"
                          class="d-flex"
                        >
                          <el-select
                            v-model="formatTableColumnsValue.value.align"
                            :placeholder="`${$t('t-place-table-config-align')}`"
                          >
                            <el-option
                              v-for="item in DataForm.formatAlign"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t-table-config-type')}`"
                          class="d-flex"
                        >
                          <el-select
                            v-model="formatTableColumnsValue.value.type"
                            :placeholder="`${$t('t-place-table-config-type')}`"
                          >
                            <el-option
                              v-for="item in DataForm.typeColumns"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </el-select>
                        </el-form-item>
                        <el-row :gutter="20">
                          <el-col :md="12">
                            <el-switch v-model="formatTableColumnsValue.value.defaultHide"
                              :active-text="$t('t-hide-bar-default')" />
                          </el-col>
                          <el-col :md="12" style="text-align: right;">
                            <el-switch v-model="formatTableColumnsValue.value.visible" class="mb-2"
                            :active-text="$t('unvisible_columns')" :inactive-text="$t('visible_columns')" />
                          </el-col>

                          <el-col :md="24" class="d-flex">
                            <el-switch v-model="formatTableColumnsValue.value.icon.visible" class="mb-2"
                              :active-text="$t('t-display-bar-icon')" />
                            <div class="icon-columns">
                              <img
                                v-if="
                                  formatTableColumnsValue.value.icon.file_id
                                    ?.length > 0
                                "
                                :src="
                                  renderIconColumns(
                                    formatTableColumnsValue.value.icon.file_id
                                  )
                                "
                                alt=""
                              />
                              <img
                                v-else
                                src="@/assets/icon/none-icon.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <select-icons-columns
                                :listIcons="listIconsColumns"
                                :disabled="
                                  !formatTableColumnsValue.value.icon.visible
                                "
                                @setIcon="setIcon"
                                :key="keySelectIcon"
                              ></select-icons-columns>
                            </div>
                            <!-- nút tải lên -->
                            <span style="margin: 0 5px">{{ $t("t-or") }}</span>
                            <div class="" @click="keySelectIcon += 1">
                              <label for="uploadLogo" class="label-upload-icons" :class="{
                                disableUploadIcon:
                                  !formatTableColumnsValue.value.icon.visible,
                              }">
                                <img src="../../assets/icon/upload_white.svg" alt="upload" />
                                <span>{{ $t("t_upload") }}</span>
                              </label>
                            </div>

                            <input
                              type="file"
                              accept=".jpg, .png, .jpeg, .svg, .JPEG, .ico"
                              :disabled="
                                !formatTableColumnsValue.value.icon.visible
                              "
                              id="uploadLogo"
                              style="display: none"
                              @change="uploadIconColumns"
                            />
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :md="14">
                        <el-row :gutter="5">
                          <el-col :md="6">
                            <el-color-picker
                              v-model="formatTableColumnsValue.value.color"
                              class="mb-2"
                            />
                            <span style="margin-left: 10px">{{
                              $t("t-table-config-color")
                            }}</span>
                          </el-col>
                          <el-col :md="6">
                            <el-switch
                              v-model="formatTableColumnsValue.value.bold"
                              :active-text="`${$t('font_style_bold')}`"
                            />
                          </el-col>
                          <el-col :md="6">
                            <el-switch
                              v-model="formatTableColumnsValue.value.italic"
                              :active-text="`${$t('font_style_italic')}`"
                              class="mb-2"
                            />
                          </el-col>
                          <el-col :md="6">
                            <el-switch
                              v-model="formatTableColumnsValue.value.underline"
                              :active-text="`${$t('font_style_underline')}`"
                              class="mb-2"
                            />
                          </el-col>
                          <el-col :md="12">
                            <el-switch
                              v-model="
                                formatTableColumnsValue.value.sort.is_allowed
                              "
                              :active-text="`${$t('t-allow-sort')}`"
                              @change="changeAllowSortAndFilter('sort')"
                            />
                          </el-col>
                          <el-col :md="12">
                            <el-form-item
                              :label="`${$t('t-type-sort')}`"
                              class="d-flex"
                              :class="{
                                disabledLabel:
                                  !formatTableColumnsValue.value.sort
                                    .is_allowed,
                              }"
                            >
                              <el-select
                                v-model="
                                  formatTableColumnsValue.value.sort.type
                                "
                                :placeholder="`${$t('t-type-sort')}`"
                                :disabled="
                                  !formatTableColumnsValue.value.sort.is_allowed
                                "
                                style="width: 140px"
                              >
                                <el-option
                                  v-for="item in DataForm.typeSort"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <el-col :md="12">
                            <el-switch
                              v-model="
                                formatTableColumnsValue.value.filter.is_allowed
                              "
                              :active-text="`${$t('t-allow-filter')}`"
                              @change="changeAllowSortAndFilter('filter')"
                            />
                          </el-col>
                          <el-col :md="12">
                            <el-form-item
                              :label="`${$t('t-type-filter')}`"
                              class="d-flex"
                              :class="{
                                disabledLabel:
                                  !formatTableColumnsValue.value.filter
                                    .is_allowed,
                              }"
                            >
                              <el-select
                                v-model="
                                  formatTableColumnsValue.value.filter
                                    .input_type
                                "
                                :placeholder="`${$t('t-type-filter')}`"
                                :disabled="
                                  !formatTableColumnsValue.value.filter
                                    .is_allowed
                                "
                                style="width: 140px"
                              >
                                <el-option
                                  v-for="item in DataForm.typeFilter"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                />
                              </el-select>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-form-item :label="$t('t-sort-default')" class="sort-default">
                          <el-input-number style="max-width: 250px;" :precision="0"
                            v-model="formatTableColumnsValue.value.sort.default" controls-position="right" />
                        </el-form-item>

                        <el-form-item
                          :label="`${$t('t_header_tooltip')}`"
                          class="d-flex"
                        >
                          <el-input
                            type="text"
                            v-model="formatTableColumnsValue.value.tooltip"
                            :placeholder="`${$t('t_place_tooltip')}`"
                            style="max-width: 250px"
                            clearable
                          />
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t_code_column_tooltip')}`"
                          class="d-flex"
                        >
                          <el-input
                            type="text"
                            v-model="
                              formatTableColumnsValue.value.cell_tooltip
                                .source_column_code
                            "
                            :placeholder="`${$t('t_place_code_tooltip')}`"
                            clearable
                            style="max-width: 250px"
                            @change="
                              formatTableColumnsValue.value.cell_tooltip.source_column_code =
                                MethodService.trimSpace(
                                  formatTableColumnsValue.value.cell_tooltip
                                    .source_column_code
                                )
                            "
                          />
                        </el-form-item>
                        <el-form-item
                          :label="`${$t('t_select_dashboard_popup')}`"
                          class="d-flex"
                        >
                          <div style="width: 400px">
                            <el-select
                              v-model="
                                formatTableColumnsValue.value.popup.popup_id
                              "
                              clearable
                              @clear="
                                formatTableColumnsValue.value.popup.popup_id =
                                  undefined
                              "
                              :placeholder="`${$t(
                                't_place_select_dashboard_popup'
                              )}`"
                              filterable
                              style="max-width: 250px"
                            >
                              <el-option
                                v-for="item in dropListDashboardPopup"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                              />
                            </el-select>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="`${$t(
                                't_reload_list_dashboard_popup'
                              )}`"
                              placement="top"
                            >
                              <el-button
                                plain
                                style="margin: 0 10px 0 15px"
                                @click="getListDashboardPopup"
                                ><i class="ri-refresh-line"></i
                              ></el-button>
                            </el-tooltip>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="`${$t('t_add_dashboard_popup')}`"
                              placement="top"
                            >
                              <el-link
                                :href="`${locationHref + '/dashboard-popup'}`"
                                target="_blank"
                                ><el-button type="primary" plain
                                  ><i class="ri-add-fill"></i></el-button
                              ></el-link>
                            </el-tooltip>
                          </div>
                        </el-form-item>
                        <el-form-item :label="`${$t('t-table-formatString')}`">
                          <el-input
                            type="textarea"
                            :rows="5"
                            v-model="formatTableColumnsValue.value.formatString"
                            :placeholder="`${$t('t-place-table-formatString')}`"
                            clearable
                            :input-style="{
                              wordBreak: 'break-word',
                            }"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-form-item>
                      <el-checkbox
                        v-model="showFormatLink"
                        :label="$t('t-configure-conditions-for-Links')"
                        size="large"
                      />
                    </el-form-item>
                    <el-form-item v-show="showFormatLink">
                      <template #label>
                        <span
                          >{{
                            $t("t-field-name-contains-the-comparison-value")
                          }}
                        </span>
                        <span class="label-requied" slots="label">*</span>
                      </template>
                      <el-input type="text" v-model="formatTableColumnsValue.value.cellLink.valueColumn
                        " :placeholder="$t('t-name-of-the-column-after-As')" @change="setNameCellLink()" clearable />
                    </el-form-item>
                    <el-form-item v-show="showFormatLink" class="table-link">
                      <el-table :data="dataLink.value">
                        <el-table-column min-width="50" align="start">
                          <template #header>
                            <span>{{ $t("t-list-comparison-values") }}</span>
                            <span class="label-requied" slots="label">*</span>
                          </template>
                          <template #default="scope">
                            <el-tag
                              v-for="tag in scope.row.listValue"
                              :key="tag"
                              class="mx-1"
                              closable
                              :disable-transitions="false"
                              @close="handleClose(tag, scope.$index)"
                            >
                              {{ tag }}
                            </el-tag>
                            <el-input
                              ref="InputRef"
                              v-model="scope.row.inputValue"
                              class="ml-1 w-20"
                              style="width: 120px"
                              :placeholder="$t('t-comparison-value')"
                              :class="{
                                error_value:
                                  errorValue[scope.$index] === scope.$index,
                              }"
                              @keyup.enter="
                                handleInputConfirm(
                                  scope.$index,
                                  scope.row.inputValue
                                )
                                " @blur="
                                  handleInputConfirm(
                                    scope.$index,
                                    scope.row.inputValue
                                  )
                                  " />
                            <span v-if="errorValue[scope.$index] === scope.$index" class="error">{{ $t("valid_required") }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column min-width="100" align="start">
                          <template #header>
                            <span>{{ $t("format_string") }} </span>
                            <span class="label-requied" slots="label">*</span>
                          </template>
                          <template #default="scope">
                            <el-input
                              type="textarea"
                              :autosize="{ minRows: 1, maxRows: 4 }"
                              v-model="scope.row.formatString"
                              :placeholder="$t('t_place_format_string')"
                              :class="{
                                error_value:
                                  errorFormatString[scope.$index] ===
                                  scope.$index,
                              }"
                              @change="
                                changeLink(
                                  scope.row.listValue,
                                  scope.row.formatString,
                                  scope.$index,
                                  'formatString'
                                )
                                " />
                            <span v-if="
                              errorFormatString[scope.$index] === scope.$index
                            " class="error">{{ $t("valid_required") }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="$t('t-action')"
                          align="center"
                          width="100px"
                        >
                          <template #default="scope">
                            <div class="btn-group-thao-tac">
                              <button
                                type="button"
                                class="btn btn-soft-info waves-effect waves-light btn-sm"
                                v-b-popover.hover.left="$t('t-add')"
                                v-if="scope.row.action === 'add'"
                                @click="addParam(scope.row, scope.$index)"
                              >
                                <i class="ri-add-circle-line"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-soft-danger waves-effect waves-light btn-sm"
                                v-b-popover.hover.left="$t('t-delete')"
                                v-else
                                @click="deleteFormmatLink(scope.$index)"
                              >
                                <i class="ri-delete-bin-6-line"></i>
                              </button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-form-item>
                    <el-form-item v-show="showFormatLink" :label="$t('format_string_for_other_comparison_values_above')">
                      <el-input type="textarea" :rows="5" v-model="formatTableColumnsValue.value.cellLink.defaultLink.formatString" 
                        :placeholder="`${$t('t_place_format_string')} (${$t('t-not-required')})`" clearable :input-style="{
                          wordBreak: 'break-word',
                        }"
                      />
                    </el-form-item>
                    <div class="text-center">
                      <el-button
                        type="primary"
                        @click="createTableColumnsConfig"
                        :loading="loadingBtn"
                        v-if="stypeTableConfig == 'create'"
                        :disabled="
                          !formatTableColumnsValue.value.field_name ||
                          (formatTableColumnsValue.value.filter?.is_allowed &&
                            !formatTableColumnsValue.value?.code) ||
                          (showFormatLink &&
                            (!formatTableColumnsValue.value.cellLink
                              .valueColumn ||
                              !formatTableColumnsValue.value.cellLink.valueColumn.trim() ||
                              dataLink.value.length <= 1))
                        "
                        >{{ $t("t-confirm") }}</el-button
                      >
                      <el-button
                        type="primary"
                        @click="updateTableColumnsConfig"
                        :loading="loadingBtn"
                        v-if="stypeTableConfig == 'update'"
                        :disabled="
                          !formatTableColumnsValue.value.field_name ||
                          (formatTableColumnsValue.value.filter.is_allowed &&
                            !formatTableColumnsValue.value.code) ||
                          (showFormatLink &&
                            (!formatTableColumnsValue.value.cellLink
                              .valueColumn ||
                              !formatTableColumnsValue.value.cellLink.valueColumn.trim() ||
                              dataLink.value.length <= 1))
                        "
                        >{{ $t("t-confirm") }}</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </div>
            </el-form-item>

            <el-form-item
              :label="`${$t('t-network')}`"
              v-if="DataCardItems.value.type.includes('network')"
            >
              <el-select
                v-model="DataCardItems.value.network.service_ids"
                multiple
                :multiple-limit="
                  DataCardItems.value.type === 'stats-network-multi-serv'
                    ? 20
                    : 1
                "
                filterable
                default-first-option
                :reserve-keyword="false"
                :placeholder="`${$t('t-network-service')}`"
              >
                <el-option
                  v-for="item in listService"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-secondary btn-border btn-select-type mx-2"
            @click="stepNumber -= 1"
            v-if="props.typeShowCardItemConfig === 'create'"
          >
            {{ $t("t-back") }}
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-border btn-select-type mx-2"
            @click="stepNumber += 1"
            :disabled="
              !DataCardItems.value.title ||
              (DataCardItems.value.chart.alert?.sql &&
                !DataCardItems.value.chart.alert.sql.includes('@table')) ||
              !checkSqlFormats(
                DataCardItems.value.chart.alert.sql_table_formats
              ) ||
              isDisabledButtonNext
            "
          >
            {{ $t("t-next") }}
          </button>
        </div>
      </div>

      <div v-if="stepNumber == 3">
        <el-form-item :label="`${$t('t-service')}`" prop="">
          <el-select
            v-model="DataCardItems.value.service_id"
            :placeholder="`${$t('t-select-service')}`"
            @change="getIdService"
            class="service-card-items"
            clearable
            filterable
          >
            <!-- bỏ không cho chọn dịch vụ :disabled="DataCardItems.value.disableService" -->
            <el-option
              v-for="item in listService"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="`${$t('t-table-config')}`" prop="table_config_id">
          <el-select
            v-model="DataCardItems.value.table_config_id"
            :placeholder="`${$t('t-select-table-config')}`"
            filterable
          >
            <el-option
              v-for="item in listTableConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="SQL" prop="sql">
          <el-input
            v-model="DataCardItems.value.sql"
            :placeholder="`${$t('t-place-sql')}`"
            :rows="5"
            type="textarea"
            :input-style="{
              wordBreak: 'break-word',
            }"
          />
        </el-form-item>
        <el-form-item
          :label="`${$t('t-sql-table-formats')}`"
          prop="sql_table_formats"
        >
          <el-input
            v-model="DataCardItems.value.sql_table_formats"
            :placeholder="`${$t('t-place-sql-formats')}`"
            :rows="5"
            type="textarea"
            :input-style="{
              wordBreak: 'break-word',
            }"
          />
        </el-form-item>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-outline-secondary btn-border btn-select-type mx-2"
            @click="stepNumber -= 1"
          >
            {{ $t("t-back") }}
          </button>
          <el-button
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border mx-2"
            @click="addCardItems(ruleFormRef)"
            v-if="props.typeShowCardItemConfig === 'create'"
          >
            {{
              DataCardItems.value.type?.includes("table")
                ? $t("t-add-table")
                : $t("t-add-chart")
            }}
          </el-button>
          <el-button
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border mx-2"
            @click="updateCardItems(ruleFormRef)"
            v-if="props.typeShowCardItemConfig === 'update'"
          >
            {{
              DataCardItems.value.type?.includes("table")
                ? $t("t-update-table")
                : $t("t-update-chart")
            }}
          </el-button>
        </div>
      </div>
    </el-form>
  </el-dialog>
  <el-dialog
    v-model="showDialogTooltipColors"
    :title="$t('t_suggested_color_palette')"
    width="60%"
    :close-on-click-modal="false"
  >
    <tooltip-colors></tooltip-colors>
  </el-dialog>
</template>

<script setup>
import mushroom from "cem-probe-api";
import {
  defineEmits,
  defineProps,
  onMounted,
  reactive,
  ref,
  watch,
  computed,
} from "vue";
import Swal from "sweetalert2";
import toastr from "toastr";
import { useRoute } from "vue-router";
import Validate from "@/service/ValidService";
import MethodService from "@/service/MethodService";
import DataForm from "./dataFormCardItemsConfig";
import TooltipColors from "../TooltipColors.vue";
let _ = require("lodash");
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const ruleFormRef = ref();
const router = useRoute();
const centerDialogVisible = ref(false);
const showTableColumnsConfig = ref(false);
const loadingBtn = ref(false);
const titleCardItem = ref("");
const infoChartType = ref("");
const listService = ref([]);
const listTableConfig = ref([]);
const listTableConfigChart = ref([]);
const keySelectIcon = ref(0);
const idService = ref("");
const indexTableColumnsConfig = ref(""); // vị trí cột của bảng table config
const tempDataTableColumsConfig = ref([]); // lưu tạm giá trị của table config
const stypeTableConfig = ref("create"); // trạng thái của table config
const showDialogTooltipColors = ref(false);
const locationHref = ref(window.origin);
const DataCardItems = reactive({
  value: MethodService.copyObject(DataForm.dataCardItems),
});
const formatTableColumnsValue = reactive({
  value: MethodService.copyObject(DataForm.formatColumnsTable),
});
import RenderIconChart from "../../components/RenderIconChart.vue";
import TableColumnsConfig from "../../components/TableColumnsConfig.vue";
import ColorGradientBarChart from "../../components/ColorGradientBarChart.vue";
import SelectIconsColumns from "../../components/SelectIconsColumns.vue";
import { ListService } from "../../stores/ListService";
import { ListDashboardPopup } from "../../stores/ListDashboardPopup";
import chartBarVertSingle from "../../assets/images/chart/chart-bar-vert-single.svg";
import chartBarVertMulti from "../../assets/images/chart/chart-bar-vert-multi.svg";
import chartBarHoriSingle from "../../assets/images/chart/chart-bar-hori-single.svg";
import chartBarHoriMulti from "../../assets/images/chart/chart-bar-hori-multi.svg";
import chartLineSingle from "../../assets/images/chart/chart-line-single.svg";
import chartLineMulti from "../../assets/images/chart/chart-line-multi.svg";
import chartLineAreaSingle from "../../assets/images/chart/chart-line-area-single.svg";
import chartLineAreaMulti from "../../assets/images/chart/chart-line-area-multi.svg";
import chartPie from "../../assets/images/chart/chart-pie.svg";
import groupText from "../../assets/images/chart/group-text.svg";
import errNetwork2segments from "../../assets/images/chart/err-network-2segments.svg";
import errNetwork3segments from "../../assets/images/chart/err-network-3segments.svg";
import statsNetworkMultiServ from "../../assets/images/chart/stats-network-multi-serv.svg";
import timeLine from "../../assets/images/chart/time-line.svg";
// import infoSingle from "../../assets/images/chart/info-single.svg";
import table from "../../assets/images/chart/table.svg";
import tableScreen from "../../assets/images/chart/table-screen.svg";
import chartStackBarVert from "../../assets/images/chart/chart-stack-bar-vert.svg";
import chartStackBarHori from "../../assets/images/chart/chart-stack-bar-hori.svg";
import chartRadar from "../../assets/images/chart/chart-radar.svg";
import { abortAPI } from "@/stores/abortAPI";
import { Filter } from "mushroomjs";
import { listMenu } from "@/stores/ListMenu";
const storeListMenu = listMenu();
const abortController = new AbortController();
const storeAbortAPI = abortAPI();
const storeListService = ListService();
const listDashboardPopup = ListDashboardPopup();
const isDisabledButtonNext = ref(false);
const emit = defineEmits([""]);
const props = defineProps({
  cardIdToCardItemsConfig: String,
  cardTitleToCardItemsConfig: String,
  dataTableConfig: Array,
  idCardItem: String,
  showCardItemConfig: String,
  stepNumberForCardItem: Number,
  typeShowCardItemConfig: String,
  listCardItem: Array,
  propListDashboardPopup: Array,
  sumWidthCardItem: Number,
});
const showSelectIconForColumns = ref(false);
const stepNumber = ref(1);
const activeNames = ref(["1"]);
const checkFalse = ref(0);
const dataTableConfigRender = ref([]);
const dataChartConfigIn = ref([]);
const colorConfigIn = ref([]);
const listIconsColumns = ref([]);
const nameDashboard = ref("");
const nameDashboardService = ref("");
const nameMenuSelect = ref("");
const nameCard = ref("");
const listCardItems = ref([]);
const idCardSelect = ref("");
const cardItemsBlock = ref([]);
const sumWidthCardItem = ref(0);
const arrayHeightCardItem = ref([]);
const loadingGetListCardItems = ref(false);
const cardItemCopyObj = ref({});
const listCardItemCopyObj = ref([]);
const showFormatLink = ref(false);
const dataLink = reactive({
  value: [
    {
      listValue: [],
      formatString: undefined,
      inputValue: undefined,
      action: "add",
    },
  ],
});
const errorFormatString = ref([]);
const errorValue = ref([]);
const isSelectMenuPhanCap = ref(false);
const inputVisible = ref(false);
const InputRef = ref();
const serviceChart = ref("");
const typeDashboard = ref("");
const listCardItemsNew = ref([]);
const nameDashboardDynamic = ref("");
const disableSelectListDynamic = ref(true);
const listDynamic = ref([]);
const countClickCardItemBlock = ref(0);
const keyTableConfig = ref(0);
const disableSelectListService = ref(true);
const dropListDashboardPopup = computed(() => {
  return listDashboardPopup.showListDashboardPopup;
});

const updateListDashboardPopup = async () => {
  await getListDashboardPopup();
};

const setValueCode = () => {
  formatTableColumnsValue.value.code = formatTableColumnsValue.value.code
    ? formatTableColumnsValue.value.code.trim()
    : undefined;
};

const handleClose = (tag, index) => {
  dataLink.value[index].listValue.splice(
    dataLink.value[index].listValue.indexOf(tag),
    1
  );
  errorValue.value[index] =
    dataLink.value[index].listValue.length === 0 ? index : undefined;
};

const handleInputConfirm = (index, inputValue) => {
  if (inputValue && inputValue.trim()) {
    dataLink.value[index].listValue.push(inputValue.trim());
    errorValue.value[index] =
      dataLink.value[index].listValue.length === 0 ? index : undefined;
  }
  inputVisible.value = false;
  dataLink.value[index].inputValue = "";
};

const changeLink = (tag, value, index, label) => {
  if (label === "listValue") {
    errorValue.value[index] = tag.length === 0 ? index : undefined;
  }
  if (label === "formatString") {
    errorFormatString.value[index] = value && value.trim() ? undefined : index;
    dataLink.value[index].formatString = value ? value.trim() : undefined;
  }
};

const addParam = (row, index) => {
  if (row.listValue.length > 0 && row.formatString) {
    const length = dataLink.value.length;
    dataLink.value[length - 1] = {
      listValue: row.listValue,
      formatString: row.formatString,
      inputValue: row.inputValue,
      action: "",
    };
    dataLink.value.push({
      listValue: [],
      formatString: undefined,
      inputValue: undefined,
      action: "add",
    });
  } else {
    errorValue.value[index] = row.listValue.length === 0 ? index : undefined;
    errorFormatString.value[index] = row.formatString ? undefined : index;
  }
};

const deleteFormmatLink = (index) => {
  Swal.fire({
    title: t("swal_noti"),
    text: t("toastr_ask_before_delete_format_string_of_comparison_value"),
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    cancelButtonText: t("swal_cancel"),
    confirmButtonText: t("swal_accept"),
    showCancelButton: true,
    allowOutsideClick: false,
    customClass: {
      container: "swalDeleteColumnConfig",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      dataLink.value.splice(index, 1);
      errorValue.value[index] = undefined;
      errorFormatString.value[index] = undefined;
    }
  });
};

const linkImg = (type) => {
  if (type == "table") return table;
  if (type == "table-screen") return tableScreen;
  if (type == "chart-bar-vert-single") return chartBarVertSingle;
  if (type == "chart-bar-vert-multi") return chartBarVertMulti;
  if (type == "chart-bar-hori-single") return chartBarHoriSingle;
  if (type == "chart-bar-hori-multi") return chartBarHoriMulti;
  if (type == "chart-line-single") return chartLineSingle;
  if (type == "chart-line-multi") return chartLineMulti;
  if (type == "chart-line-area-single") return chartLineAreaSingle;
  if (type == "chart-line-area-multi") return chartLineAreaMulti;
  if (type == "chart-pie") return chartPie;
  if (type == "group-text") return groupText;
  if (type == "err-network-2segments") return errNetwork2segments;
  if (type == "err-network-3segments") return errNetwork3segments;
  if (type == "stats-network-multi-serv") return statsNetworkMultiServ;
  if (type == "time-line") return timeLine;
  if (type == "chart-stack-bar-vert") return chartStackBarVert;
  if (type == "chart-stack-bar-hori") return chartStackBarHori;
  if (type == "chart-radar") return chartRadar;
  // if (type == "info-single") return infoSingle;
};

const resetFormatTableColumnsValue = () => {
  formatTableColumnsValue.value = MethodService.copyObject(
    DataForm.formatColumnsTable
  );
  showFormatLink.value = false;
  dataLink.value = [
    {
      listValue: [],
      formatString: undefined,
      inputValue: undefined,
      action: "add",
    },
  ];
};
const clickCardItemBlock = async (item) => {
  if (item.index === 0) {
    document.getElementById(item.id).classList.add("active");
    countClickCardItemBlock.value += 1;
    cardItemsBlock.value.forEach((x) => {
      if (x.id === item.id) {
        x.index = 1;
      }
    });
    listCardItemCopyObj.value.push(item);
  } else {
    document.getElementById(item.id).classList.remove("active");
    countClickCardItemBlock.value -= 1;
    cardItemsBlock.value.forEach((x) => {
      if (x.id === item.id) {
        x.index = 0;
      }
    });
    listCardItemCopyObj.value = listCardItemCopyObj.value.filter(
      (x) => x.id !== item.id
    );
  }

  // cardItemCopyObj.value = JSON.parse(JSON.stringify(item));
};

const addCardItemCopy = async (item, height, y) => {
  const cardItemCopy = item;
  cardItemCopy.title += "-Copy";
  if (sumWidthCardItem.value + cardItemCopy.width > 24) {
    cardItemCopy.x = 0;
    cardItemCopy.y = height + y;
  } else {
    cardItemCopy.x = sumWidthCardItem.value;
    cardItemCopy.y = y;
  }
  cardItemCopy.card_id = props.cardIdToCardItemsConfig;
  if (!cardItemCopy.settings) {
    cardItemCopy.settings = {
      add_start_days: null,
      add_end_days: null,
    };
  }
  if (cardItemCopy.table_config_id) {
    const getServiceId = await mushroom.table_config.findByIdAsync(
      {
        id: cardItemCopy.table_config_id,
      },
      {
        abortController: abortController,
      }
    );
    cardItemCopy.service_id = getServiceId.result.service_id;
  }

  try {
    const newId = await mushroom.card_item.createAsync(cardItemCopy);
    cardItemCopyObj.value.id = newId.result;
    emit("emitGetAllCardItem");
    toastr.success(t("toastr_copy_card_item_success"));
  } catch (error) {
    toastr.error(t("toastr_copy_card_item_fail"));
  }
};

const addListCardItemCopy = async (item, height, y) => {
  const cardItemCopy = item;
  cardItemCopy.title += "-Copy";
  if (sumWidthCardItem.value + cardItemCopy.width > 24) {
    cardItemCopy.x = 0;
    cardItemCopy.y = height + y;
  } else {
    cardItemCopy.x = sumWidthCardItem.value;
  }
  cardItemCopy.card_id = props.cardIdToCardItemsConfig;
  if (!cardItemCopy.settings) {
    cardItemCopy.settings = {
      add_start_days: null,
      add_end_days: null,
    };
  }
  if (cardItemCopy.table_config_id) {
    const getServiceId = await mushroom.table_config.findByIdAsync(
      {
        id: cardItemCopy.table_config_id,
      },
      {
        abortController: abortController,
      }
    );
    cardItemCopy.service_id = getServiceId.result.service_id;
  }

  try {
    const response = await mushroom.card_item.createAsync(cardItemCopy);
    if (response.result) toastr.success(t("toastr_copy_card_item_success"));
  } catch (error) {
    toastr.error(t("toastr_copy_card_item_fail"));
  }
};

const copyCardItemNext = async () => {
  listCardItemsNew.value = listCardItemsNew.value.sort((x, y) => {
    if (x.y < y.y) {
      return -1;
    }
    return 1;
  });
  listCardItemCopyObj.value = listCardItemCopyObj.value.filter((item) => item);

  if (listCardItemCopyObj.value.length === 1) {
    cardItemCopyObj.value = listCardItemCopyObj.value[0];
    let card_item = {};
    card_item.height = 0;
    card_item.y = 0;
    if (listCardItemsNew.value.length > 0) {
      card_item = listCardItemsNew.value[listCardItemsNew.value.length - 1];
    }
    await addCardItemCopy(cardItemCopyObj.value, card_item.height, card_item.y);
    stepNumber.value += 1;
    DataCardItems.value = cardItemCopyObj.value;
    DataCardItems.value.id = cardItemCopyObj.value.id;
    DataCardItems.value.chart.alert = {
      sql: undefined,
      sql_table_formats: [],
      table_config_id: undefined,
    };
    if (DataCardItems.value.sql_table_formats?.length > 1)
      DataCardItems.value.sql_table_formats =
        DataCardItems.value.sql_table_formats.join("\n");

    if (DataCardItems.value.chart.alert?.sql_table_formats?.length > 1)
      DataCardItems.value.chart.alert.sql_table_formats =
        DataCardItems.value.chart.alert.sql_table_formats.join("\n");
    dataTableConfigRender.value = cardItemCopyObj.value.table?.format;

    // await getIdService(router.params.id);
    // DataCardItems.value.service_id = router.params.id;
    getAllTableConfig(cardItemCopyObj.value.service_id);
    getAllTableConfigChart(cardItemCopyObj.value.service_id);
    DataCardItems.value.disableService = true;
    dataTableConfigRender.value = [];
    dataChartConfigIn.value = [];
    colorConfigIn.value = [];
    listCardItems.value = [];
    cardItemsBlock.value = [];
    countClickCardItemBlock.value = 0;
    nameDashboard.value = "";
    nameDashboardService.value = "";
    nameDashboardDynamic.value = "";
    nameCard.value = "";
    emit("changeTypeShowCardItemConfig", "update");
    await getInfoCardItems(cardItemCopyObj.value.id);
    listCardItemCopyObj.value = [];
  } else {
    let height = 0;
    let y = 0;

    for (let index = 0; index < listCardItemCopyObj.value.length; index++) {
      if (index === 0) {
        let card_item = {};
        card_item.height = 0;
        card_item.y = 0;
        if (listCardItemsNew.value.length > 0) {
          card_item = listCardItemsNew.value[listCardItemsNew.value.length - 1];
        }
        height = card_item.height;
        y = card_item.y;
      } else {
        const card_item = listCardItemCopyObj.value[index - 1];
        y += height;
        height = card_item.height;
      }
      await addListCardItemCopy(listCardItemCopyObj.value[index], height, y);
    }
    emit("emitGetAllCardItem");
    beforeCloseDialog();
    listCardItemCopyObj.value = [];
  }
};

const getListMenu = async (list) => {
  const listMenuId = list.map((item) => item.menu_id);
  const type = localStorage.getItem("type_login");

  try {
    const response = await mushroom.menu.getAllAsync({
      filters: Filter.and(
        Filter.in("id", listMenuId),
        Filter.eq("target_domain", type)
      ).build(),
    });
    response.result.forEach((item) => {
      const dynamic = list.find((x) => x.menu_id === item.id);
      listDynamic.value.push({
        value: dynamic.id,
        label: item.label,
      });
    });
    listDynamic.value = _.sortBy(listDynamic.value, [
      function (o) {
        return o.label.toLowerCase();
      },
    ]);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListDynamic = async (listIds) => {
  if (!listIds) return;
  listDynamic.value = [];
  try {
    const response = await mushroom.dynamic_dashboard.getAllAsync({
      filters: Filter.in("id", listIds).build(),
    });
    if (response.result.length === 0) return;
    await getListMenu(response.result);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getTypeForDashboard = (item) => {
  let strTypeDashboard = item.slice(0, item.lastIndexOf("-"));
  let strTargetId = item.slice(item.lastIndexOf("-") + 1);
  const pattern = /[a-f0-9]{24}/;
  const matchTypeDashboardResult = strTypeDashboard.match(pattern);
  let typeDashboard = strTypeDashboard.replace(
    ":" + matchTypeDashboardResult[0],
    ""
  );
  let targetId = strTargetId.slice(9);
  listCardItems.value = [];
  cardItemsBlock.value = [];
  countClickCardItemBlock.value = 0;
  nameCard.value = "";
  getListDashboardFromSelectMenu(typeDashboard, targetId);
};
const getListDashboardFromSelectMenu = async (type, id) => {
  let typeFilter = "";
  if (type === "service") typeFilter = "service_id";
  if (type === "uc") typeFilter = "uc_id";
  if (type === "tablescreen") typeFilter = "table_screen_id";
  if (type === "dynamic") typeFilter = "dynamic_dashboard_id";

  try {
    const response = await mushroom.card.getAllAsync({
      filters: Filter.and(
        Filter.eq("dashboard", type),
        Filter.eq(typeFilter, id)
      ).build(),
      sort: "index",
    });
    if (response.result?.length > 0) {
      response.result.forEach((item) => {
        listCardItems.value.push({
          value: item.id,
          label: item.title,
        });
      });
    }
  } catch (e) {
    MethodService.showError(e);
  }
};
const getListDashboard = async (type) => {
  nameMenuSelect.value = "";
  isSelectMenuPhanCap.value = nameDashboard.value === "menu" ? true : false;
  typeDashboard.value = type;
  countClickCardItemBlock.value = 0;
  nameCard.value = "";
  disableSelectListService.value = type === "service" ? false : true;
  disableSelectListDynamic.value = type === "dynamic" ? false : true;

  type == "dynamic"
    ? (disableSelectListDynamic.value = false)
    : (disableSelectListDynamic.value = true);
  if (router.name == "DichVu" && type == "service") {
    nameDashboardService.value = router.params.id;
    await getListDashboardService(router.params.id);
  } else {
    nameDashboardService.value = "";
    nameDashboardDynamic.value = "";
    try {
      const response = await mushroom.card.getAllAsync({
        filters: Filter.eq("dashboard", type).build(),
        sort: "index",
      });
      listCardItems.value = [];
      cardItemsBlock.value = [];
      if (response.result?.length > 0) {
        if (type !== "service" && type !== "dynamic") {
          response.result.forEach((item) => {
            listCardItems.value.push({
              value: item.id,
              label: item.title,
            });
          });
        }
      }
      if (type === "dynamic") {
        const listDynamicId = response.result.map(
          (item) => item.dynamic_dashboard_id
        );
        if (listDynamicId.length > 0) {
          const listIds = [...new Set(listDynamicId)];
          getListDynamic(listIds);
        }
        if (router.name == "Dynamic" && type == "dynamic") {
          nameDashboardDynamic.value = router.params.id;
          await getListDashboardDynamic(router.params.id);
        }
      }
    } catch (e) {
      console.error("Có lỗi: %o", e);
      MethodService.showError(e.code);
    }
  }
};

const getListDashboardService = async (idService) => {
  listCardItems.value = [];
  cardItemsBlock.value = [];
  countClickCardItemBlock.value = 0;
  nameCard.value = "";
  try {
    const response = await mushroom.card.getAllAsync({
      fields: "id,dashboard,service_id,uc_id,title,header,index",
      filters: Filter.and(
        Filter.eq("dashboard", "service"),
        Filter.eq("service_id", idService)
      ).build(),
    });

    if (response.result?.length > 0) {
      response.result.forEach((item) => {
        listCardItems.value.push({
          value: item.id,
          label: item.title,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListDashboardDynamic = async (idDynamic) => {
  listCardItems.value = [];
  cardItemsBlock.value = [];
  countClickCardItemBlock.value = 0;
  nameCard.value = "";
  try {
    const response = await mushroom.card.getAllAsync({
      fields: "id,dashboard,dynamic_dashboard_id,uc_id,title,header,index",
      filters: Filter.and(
        Filter.eq("dashboard", "dynamic"),
        Filter.eq("dynamic_dashboard_id", idDynamic)
      ).build(),
    });
    if (response.result?.length > 0) {
      response.result.forEach((item) => {
        listCardItems.value.push({
          value: item.id,
          label: item.title,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListCardItems = async (e) => {
  idCardSelect.value = e;
  countClickCardItemBlock.value = 0;
  cardItemsBlock.value = [];
  loadingGetListCardItems.value = true;
  try {
    const response = await mushroom.card_item.getAllAsync({
      filters: Filter.eq("card_id", idCardSelect.value).build(),
      fields:
        "id,card_id,type,title,general,x,y,width,height,table_config_id,y_axis,alert,table,chart,network,info_single,sql,sql_table_formats,settings",
    });

    if (response.result?.length > 0) {
      let arrayTableConfigId = response.result.map((item) =>
        mushroom.table_config.findByIdAsync(
          { id: item.table_config_id },
          {
            abortController: abortController,
          }
        )
      );
      await Promise.all(arrayTableConfigId).then((listData) => {
        listData.forEach((item_data) => {
          response.result.forEach((item_res) => {
            if (item_res.table_config_id === item_data.result.id) {
              item_res.service_id = item_data.result.service_id;
            }
            if (!item_res.settings) {
              item_res.settings = {
                add_start_days: undefined,
                add_end_days: undefined,
              };
            }
            item_res.index = 0;
          });
        });
      });

      cardItemsBlock.value = response.result;
      arrayHeightCardItem.value = [];
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  } finally {
    loadingGetListCardItems.value = false;
  }
};

const getListIconsColumns = async () => {
  try {
    const response = await mushroom.icon.getAllAsync();
    if (response.result?.length > 0)
      listIconsColumns.value = response.result.map((item) => item.file_id);
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const renderIconColumns = (iconId) => {
  if (!iconId) return;
  return mushroom.$file.linkBuilder.thumb.id(iconId).build();
};

const uploadIconColumns = async () => {
  try {
    let nameImage = document.getElementById("uploadLogo").files[0]?.name;
    let typeImage = nameImage?.slice(nameImage.indexOf(".")).toLowerCase();

    if (typeImage) {
      if (
        typeImage !== ".jpg" &&
        typeImage !== ".png" &&
        typeImage !== ".jpeg" &&
        typeImage !== ".svg" &&
        typeImage !== ".ico"
      ) {
        toastr.warning(t("t_select_format_image"));
      } else {
        let res = await mushroom.$file.uploadAsync({
          file: document.getElementById("uploadLogo").files[0],
        });
        if (res.result) {
          formatTableColumnsValue.value.icon.file_id = res.result;
          updateIconId(res.result);
          getIconForColumn(res.result);

          keySelectIcon.value += 1;
        }
      }
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const setIcon = (fileId) => {
  formatTableColumnsValue.value.icon.file_id = fileId;
  getIconForColumn(fileId);
};

const updateIconId = async (id) => {
  const icon_object = {
    file_id: id,
  };
  try {
    await mushroom.icon.createAsync(icon_object);
    await getListIconsColumns();
  } catch (error) {
    console.error("Có lỗi: %o", error);
  }
};

const getIconForColumn = async (id) => {
  formatTableColumnsValue.value.icon.urlIcon = mushroom.$file.linkBuilder.thumb
    .id(id)
    .build();
};

const changeAllowSortAndFilter = (type) => {
  if (type == "sort") formatTableColumnsValue.value.sort.type = undefined;

  if (type == "filter")
    formatTableColumnsValue.value.filter.input_type = undefined;
};

const checkSqlFormats = (value) => {
  if (value?.length > 0) {
    let count = 0;
    let stringSQL = value?.split("\n");
    stringSQL.forEach((item) => {
      if (!item.includes("@table_name")) count += 1;
    });
    if (count > 0) return false;
    return true;
  }
  return true;
};

const validateSqlFormats = (rule, value, callback) => {
  checkFalse.value = 0;
  if (!value || value.length === 0) {
    callback();
  } else {
    const numberOfLineBreaks = (value.toString().match(/\n/g) || []).length;
    if (numberOfLineBreaks >= 0) {
      let stringSQL = value?.split("\n");
      stringSQL.forEach((item) => {
        if (!item.includes("@table_name")) checkFalse.value += 1;
      });
      checkFalse.value > 0
        ? callback(new Error(t("t-place-sql-formats")))
        : callback();
    } else callback();
  }
};

const rulesForm = reactive({
  title: [
    Validate.checkMaxLength(50),
    Validate.checkSpaceString,
    Validate.required,
  ],
  type: [Validate.required, Validate.requiredChange],
  table_config_id: [Validate.required, Validate.requiredChange],
  service_id: [Validate.required, Validate.requiredChange],
  sql: [Validate.required, Validate.sql],
  sql_table_formats: [{ validator: validateSqlFormats, trigger: "blur" }],
  field_name: [Validate.required],
  formatTableColumnsValue: {
    code: [Validate.required],
  },
  chart: {
    alert: {
      sql: [Validate.sql],
      sql_table_formats: [{ validator: validateSqlFormats, trigger: "blur" }],
    },
  },
});

const deleteColums = (index) => {
  Swal.fire({
    title: t("swal_noti"),
    text: t("swal_columns_delete"),
    icon: "warning",
    confirmButtonColor: "#f46a6a",
    cancelButtonText: t("swal_cancel"),
    confirmButtonText: t("swal_accept"),
    showCancelButton: true,
    allowOutsideClick: false,
    customClass: {
      container: "swalDeleteColumnConfig",
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      toastr.success(t("toastr_delete_success"));
      dataTableConfigRender.value.splice(index, 1);
    }
  });
};

const editColums = async (index, data) => {
  // hiển thị object dữ liệu cột ở vị trí bấm
  if (!data.cell_tooltip) {
    data.cell_tooltip = {};
    data.cell_tooltip.source_column_code = "";
  }
  if (!data.popup) {
    data.popup = {};
    data.popup.popup_id = undefined;
  }
  if (
    data.cellLink &&
    data.cellLink?.valueColumn &&
    Object.keys(data.cellLink).length > 0
  ) {
    showFormatLink.value = true;
    if (data.cellLink?.links.length > 0) {
      let listLinkCell = [];

      data.cellLink.links
        .filter((item) => item.formatString)
        .forEach((item) => {
          listLinkCell.push({
            listValue: item.values,
            formatString: item.formatString,
            inputValue: undefined,
            action: "",
          });
        });
      listLinkCell.push({
        listValue: [],
        formatString: undefined,
        inputValue: undefined,
        action: "add",
      });
      dataLink.value = listLinkCell;
    }
  } else {
    showFormatLink.value = false;
    dataLink.value = [
      {
        listValue: [],
        formatString: undefined,
        inputValue: undefined,
        action: "add",
      },
    ];
  }

  await getListIconsColumns();
  errorValue.value = errorValue.value.map(() => undefined);
  errorFormatString.value = errorFormatString.value.map(() => undefined);
  indexTableColumnsConfig.value = index;
  stypeTableConfig.value = "update";
  showTableColumnsConfig.value = true;
  formatTableColumnsValue.value = { ...data };
  keySelectIcon.value += 1;
};

const updateTableColumnsConfig = () => {
  // cập nhật 1 obj table config theo vị trí hiện tại
  formatTableColumnsValue.value.showCellLink = showFormatLink.value;
  formatTableColumnsValue.value.cellLink.defaultLink.formatString =
    formatTableColumnsValue.value.cellLink.defaultLink.formatString
      ? formatTableColumnsValue.value.cellLink.defaultLink.formatString.trim()
      : undefined;

  const checkLink = dataLink.value.filter(
    (item) =>
      (!item.formatString || item.listValue.length == 0) && item.action === ""
  ).length;
  const dataFormTableColumn = { ...formatTableColumnsValue.value };
  if (checkLink > 0 && showFormatLink) return;
  showTableColumnsConfig.value = false;
  const listLinkCell = dataLink.value
    .filter((item) => item.action === "")
    .map((item) => {
      return {
        values: item.listValue,
        formatString: item.formatString,
      };
    });
  dataFormTableColumn.showCellLink =
    dataFormTableColumn.cellLink.valueColumn && showFormatLink.value
      ? true
      : false;
  dataFormTableColumn.cellLink.valueColumn =
    dataFormTableColumn.cellLink.valueColumn ?? null;
  dataFormTableColumn.cellLink.links = listLinkCell;

  dataTableConfigRender[indexTableColumnsConfig.value] = {
    ...dataFormTableColumn,
  };
  showTableColumnsConfig.value = false;
  DataCardItems.value.table.format[indexTableColumnsConfig.value] = {
    ...dataFormTableColumn,
  };
};

const createTableColumnsConfig = () => {
  // thêm mới 1 obj table config
  const checkLink = dataLink.value.filter(
    (item) =>
      (!item.formatString || item.listValue.length == 0) && item.action === ""
  ).length;
  formatTableColumnsValue.value.cellLink.defaultLink.formatString =
    formatTableColumnsValue.value.cellLink.defaultLink.formatString
      ? formatTableColumnsValue.value.cellLink.defaultLink.formatString.trim()
      : undefined;
  const dataFormTableColumn = { ...formatTableColumnsValue.value };

  if (checkLink > 0 && showFormatLink) return;
  showTableColumnsConfig.value = false;
  const listLinkCell = dataLink.value
    .filter((item) => item.action === "")
    .map((item) => {
      return {
        values: item.listValue,
        formatString: item.formatString,
      };
    });
  dataFormTableColumn.cellLink.links = listLinkCell;
  dataFormTableColumn.showCellLink = dataFormTableColumn.cellLink.valueColumn
    ? true
    : false;

  dataFormTableColumn.cellLink.valueColumn = dataFormTableColumn.cellLink
    .valueColumn
    ? dataFormTableColumn.cellLink.valueColumn.trim()
    : null;

  dataTableConfigRender.value.push({ ...dataFormTableColumn });
};

const setNameCellLink = () => {
  formatTableColumnsValue.value.cellLink.valueColumn = formatTableColumnsValue
    .value.cellLink.valueColumn
    ? formatTableColumnsValue.value.cellLink.valueColumn.trim()
    : undefined;
};

const showModalTableColumnsConfig = () => {
  stypeTableConfig.value = "create";
  showTableColumnsConfig.value = true;
  keyTableConfig.value += 1;
  keySelectIcon.value += 1;
  resetFormatTableColumnsValue();
};

const getAllTableConfigChart = async (idService) => {
  let dataFilter = {};
  if (idService) {
    dataFilter = {
      filters: Filter.and(
        Filter.eq("service_id", idService),
        Filter.eq("type", localStorage.getItem("type_login"))
      ).build(),
    };
  } else
    dataFilter = {
      filters: Filter.eq("type", localStorage.getItem("type_login")).build(),
    };
  try {
    const response = await mushroom.table_config.getAllAsync(dataFilter, {
      abortController: abortController,
    });
    listTableConfigChart.value = [];
    response.result.forEach((item) => {
      listTableConfigChart.value.push({
        value: item.id,
        label: item.name,
      });
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getAllTableConfig = async (idService) => {
  let dataFilter = {};
  if (idService) {
    dataFilter = {
      filters: Filter.and(
        Filter.eq("service_id", idService),
        Filter.eq("type", localStorage.getItem("type_login"))
      ).build(),
    };
  } else
    dataFilter = {
      filters: Filter.eq("type", localStorage.getItem("type_login")).build(),
    };
  try {
    const response = await mushroom.table_config.getAllAsync(dataFilter, {
      abortController: abortController,
    });
    listTableConfig.value = [];
    response.result.forEach((item) => {
      listTableConfig.value.push({
        value: item.id,
        label: item.name,
      });
    });
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
// const getListMenuPhanCap = () => {
//   storeListMenu.menuPhanCap.forEach((item) => {
//     listMenuPhanCap.value.push({
//       value: item.id,
//       label: item.label,
//     });
//   })
//   console.log('menu phân capá', listMenuPhanCap.value);

// }
const getAllService = async () => {
  storeListService.data.forEach((item) => {
    listService.value.push({
      value: item.id,
      label: item.name,
    });
  });
  DataCardItems.value.disableService = false;
  if (router.name == "DichVu") {
    await getAllTableConfigChart(router.params.id);
    await getAllTableConfig(router.params.id);
    DataCardItems.value.service_id = router.params.id;
    DataCardItems.value.disableService = true;
  } else if (router.name == "SessionDichVu") {
    await getAllTableConfigChart(router.query.serviceID);
    await getAllTableConfig(router.query.serviceID);
    DataCardItems.value.service_id = router.query.serviceID;
    DataCardItems.value.disableService = true;
  } else {
    await getAllTableConfigChart();
    await getAllTableConfig();
  }
};

const getIdService = async (event) => {
  idService.value = event;
  await getAllTableConfig(idService.value);
  DataCardItems.value.table_config_id = "";
};

const getIdServiceChart = async (event) => {
  await getAllTableConfigChart(event);
  DataCardItems.value.chart.alert.table_config_id = "";
};

const resetDataForm = () => {
  DataCardItems.value.title = undefined;
  ruleFormRef.value?.resetFields();
};

const addCardItems = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (!valid) return;
    DataCardItems.value.table.format =
      dataTableConfigRender.value.length > 0 ? dataTableConfigRender.value : [];
    emit("addNewCardItems", DataCardItems.value);
    stepNumber.value = 1;
    activeNames.value = 1;
    beforeCloseDialog();
  });
};

const updateCardItems = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (!valid) return;
    emit("updateNewCardItems", DataCardItems.value);
    beforeCloseDialog();
  });
};

const colorConfigOut = (data) => {
  data
    ? (isDisabledButtonNext.value = false)
    : (isDisabledButtonNext.value = true);
  data ? (DataCardItems.value.chart.data_serials = data.value) : [];
};

const multiColors = (booleanValue) => {
  DataCardItems.value.chart.multi_color_for_single_bar_chart = booleanValue;
};

const beforeCloseDialog = () => {
  serviceChart.value = "";
  nameMenuSelect.value = "";
  isSelectMenuPhanCap.value = false;
  dataTableConfigRender.value = [];
  dataChartConfigIn.value = [];
  colorConfigIn.value = [];
  listCardItems.value = [];
  listTableConfig.value = [];
  listTableConfigChart.value = [];
  cardItemsBlock.value = [];
  countClickCardItemBlock.value = 0;
  nameDashboard.value = "";
  nameDashboardService.value = "";
  nameDashboardDynamic.value = "";
  nameCard.value = "";
  disableSelectListService.value = true;
  disableSelectListDynamic.value = true;
  typeDashboard.value = "";
  DataCardItems.value = MethodService.copyObject(DataForm.dataCardItems);
  emit("closeDialogCardItemsConfig");
  getAllTableConfig();
  if (props.typeShowCardItemConfig === "create") {
    resetDataForm();
    stepNumber.value = 1;
    DataCardItems.value.type = ""; //reset chọn loại card-item
  } else stepNumber.value = 2;
  infoChartType.value = "";
  activeNames.value = 1;
};

const beforeCloseDialogTableConfig = () => {
  keyTableConfig.value += 1;
  showTableColumnsConfig.value = false;
  showSelectIconForColumns.value = false;
};

watch(
  () => props.listCardItem,
  () => {
    listCardItemsNew.value = props.listCardItem;
  }
);
watch(
  () => props.sumWidthCardItem,
  (newData) => {
    sumWidthCardItem.value = newData;
  }
);
watch(
  () => props.showCardItemConfig,
  async (newCardItemConfig) => {
    newCardItemConfig == true
      ? (centerDialogVisible.value = true)
      : (centerDialogVisible.value = false);
    countClickCardItemBlock.value = 0;
  }
);
watch(
  () => props.stepNumberForCardItem,
  async (step) => {
    if (step === 1) resetDataForm();
    if (step === 2) {
      await getInfoCardItems(props.idCardItem);
    }
  }
);
watch(
  () => props.typeShowCardItemConfig,
  (typeShowCardItemConfig) => {
    typeShowCardItemConfig === "create"
      ? (stepNumber.value = 1)
      : (stepNumber.value = 2);
  }
);
watch(
  () => props.idCardItem,
  (newDataCardItem) => {
    getInfoCardItems(newDataCardItem);
  }
);

const getAllTableConfigChartById = async (table_config_id) => {
  try {
    const response = await mushroom.table_config.findByIdAsync({
      id: table_config_id,
    });
    if (!response.result) return;
    serviceChart.value = response.result?.service_id;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getInfoCardItems = async (cardId) => {
  try {
    const response = await mushroom.card_item.findByIdAsync(
      {
        id: cardId,
      },
      {
        enabledCache: false,
      }
    );
    const tableConfigResponse = await mushroom.table_config.findByIdAsync(
      {
        id: response.result.table_config_id,
      },
      {
        abortController: abortController,
      }
    );

    if (response.result.sql_table_formats?.length > 0)
      response.result.sql_table_formats =
        response.result.sql_table_formats?.join("\n");

    if (response.result?.chart?.alert?.sql_table_formats?.length > 0)
      response.result.chart.alert.sql_table_formats =
        response.result.chart.alert.sql_table_formats?.join("\n");

    response.result.settings = response.result?.settings ?? {};
    if (!response.result.general) {
      response.result.general = {};
      response.result.general.header = {};
      response.result.general.header.color = "";
      response.result.general.header.backgroundColor = "";
    }
    if (!response.result.chart.y_axis.axisLabel) {
      response.result.chart.y_axis.axisLabel = {};
      response.result.chart.y_axis.axisLabel.rotate = "";
    }
    if (!response.result.chart.itemStyle) {
      response.result.chart.itemStyle = {};
      response.result.chart.itemStyle.borderRadius = [];
    }
    if (!response.result.chart.legend) {
      response.result.chart.legend = {};
      response.result.chart.legend.show = true;
      response.result.chart.legend.orient = "horizontal";
      response.result.chart.legend.left = null;
      response.result.chart.legend.right = null;
      response.result.chart.legend.bottom = 0;
      response.result.chart.legend.align = {};
      response.result.chart.legend.align.horizontal = "center";
      response.result.chart.legend.align.vertical = "bottom";
    } else {
      if (!response.result.chart.legend.align) {
        response.result.chart.legend.align = {};
        response.result.chart.legend.align.horizontal = "center";
        response.result.chart.legend.align.vertical = "bottom";
      }
    }
    response.result.table.format.forEach((item) => {
      if (!item.popup) {
        item.popup = {};
        item.popup.popup_id = undefined;
      }
      if (!item.filter) {
        item.filter = {};
        item.filter.is_allowed = undefined;
        item.filter.input_type = undefined;
        item.filter.is_suggestion = undefined;
        item.filter.suggest_values = [];
      }
      if (!item.sort) {
        item.sort = {};
        item.sort.is_allowed = undefined;
        item.sort.type = undefined;
      }
      if (!item.icon) {
        item.icon = {};
        item.icon.visible = undefined;
        item.icon.file_id = undefined;
      }
      item.showCellLink = true;
      if (!item.cellLink) {
        item.showCellLink = false;
        item.cellLink = {};
        item.cellLink.valueColumn = undefined;
        item.cellLink.links = [];
        item.cellLink.defaultLink = {};
        item.cellLink.defaultLink.formatString = undefined;
      }
      if (!item.cellLink.defaultLink?.formatString) {
        item.cellLink.defaultLink = {};
        item.cellLink.defaultLink.formatString = undefined;
      }
    });
    if (
      response.result.chart.data_serials &&
      response.result.chart.data_serials.length > 0
    ) {
      response.result.chart?.data_serials.forEach((item) => {
        if (!item.bg_color) {
          item.bg_color = {};
          item.bg_color.color = "";
          item.bg_color.to_color = "";
        }
        if (!item.popup) {
          item.popup = {};
          item.popup.popup_id = undefined;
        }
        if (!item.line_color) item.line_color = "";
      });
    }

    if (!response.result.chart.alert) {
      response.result.chart.alert = {
        sql: undefined,
        sql_table_formats: [],
        table_config_id: undefined,
      };
    } else {
      response.result.chart.alert = {
        sql: response.result.chart.alert?.sql ?? undefined,
        sql_table_formats: response.result.chart.alert?.sql_table_formats ?? [],
        table_config_id:
          response.result.chart.alert?.table_config_id ?? undefined,
      };
      if (response.result.chart.alert?.table_config_id) {
        await getAllTableConfigChartById(
          response.result.chart.alert.table_config_id
        );
      }
    }

    DataCardItems.value = response.result;
    dataTableConfigRender.value = response.result.table.format;
    dataChartConfigIn.value =
      response.result.chart.color?.length > 0
        ? response.result.chart.color
        : [];
    colorConfigIn.value =
      response.result.chart.data_serials?.length > 0
        ? response.result.chart.data_serials
        : [];
    titleCardItem.value = response.result.title;
    DataCardItems.value.service_id = tableConfigResponse.result.service_id;
    if (router.name == "DichVu" || router.name == "SessionDichVu") {
      DataCardItems.value.disableService = true; // disable select chọn dịch vụ
    }
    await getAllTableConfig(tableConfigResponse.result.service_id);
    tempDataTableColumsConfig.value = response.result.table.format;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const getListDashboardPopup = async () => {
  await listDashboardPopup.getAllListDashboardPopup();
  dropListDashboardPopup.value = listDashboardPopup.showListDashboardPopup;
};

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  await storeListService.getAllListService();
  await storeListMenu.getListMenuPhanCap();
  await getAllService();
  await getListIconsColumns();
  // getListMenuPhanCap()
  listCardItemsNew.value = props.listCardItem;
  sumWidthCardItem.value = props.sumWidthCardItem;
});
</script>

<style scoped lang="scss">
.btn-add-config-table {
  height: auto;
  min-height: 260px;
  width: 80px;
  background-color: #f7b84b;
  border: 2px solid #fca50f;
  border-radius: 7px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.btn-add-config-table i {
  font-size: 30px;
  color: #fff;
}

.span-step {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #15152969;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-step {
  height: 2px;
  width: 100px;
  background-color: #151529;
}

.passStep {
  background-color: #67c23a;
}

.line {
  height: 1px;
  background: #15152969;
  width: 160px;
  margin: 16px -195px 0px;
}

span.el-radio-button__inner {
  min-width: 200px;
}

:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: #f1f1f1;
}

.select-card-item {
  height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
}

.select-card-item {
  &__select {
    display: flex;
    justify-content: space-between;
  }

  &__select-item {
    width: 100%;
    margin: 0 3px;
  }
}

.chart-group span {
  color: var(--el-button-text-color, var(--el-text-color-regular)) !important;
  font-size: 13px;
}

:deep .chart-group .el-radio-button__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 250px;
}

:deep .chart-group .el-radio-button {
  border: none;
}

:deep .el-radio-button__original-radio:hover + .el-radio-button__inner {
  background-color: rgba(41, 156, 219, 0.1);
  border-color: var(
    --el-radio-button-checked-border-color,
    var(--el-color-primary)
  );
}

:deep .el-radio-button__original-radio:checked + .el-radio-button__inner {
  background-color: rgba(41, 156, 219, 0.1);
}

.el-form-item .el-form-item {
  margin-bottom: 18px;
}

:deep .table-format-cell .label-requied {
  position: relative;
  color: red;
}

:deep .table-format .el-input,
:deep .table-format .el-select {
  max-width: 325px;
}

:deep .table-format .el-form-item__content {
  text-align: end;
  position: relative;
}

.table-format .label-requied {
  position: absolute;
  color: red;
  top: 0;
  left: -10px;
}

:deep .el-input.is-disabled .el-input__inner {
  color: var(--el-input-text-color, var(--el-text-color-regular));
  -webkit-text-fill-color: var(
    --el-input-text-color,
    var(--el-text-color-regular)
  );
}

:deep .el-input.is-disabled .el-input__wrapper {
  background-color: #f5f7fa;
  box-shadow: 0 0 0 1px #e4e7ed;
}

.render-table {
  overflow: auto;
  max-width: 690px;
  display: flex;
}

.px-style {
  background-color: #f1f1f1;
  border: thin solid #dcdcdc;
  margin-left: 5px;
  border-radius: 3px;
  height: 32px;
  line-height: 100%;
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep .table-format .el-input-number {
  width: 291px !important;
}

.optionCardItem__body .el-input-number {
  width: 100%;
}

.optionCardItem__header {
  display: flex;
  align-items: center;
}

.optionCardItem__header i {
  color: #878787;
  font-size: 16px;
  margin-right: 8px;
}

:deep .table-format .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

:deep .disabledLabel .el-form-item__label {
  color: #bcbec4;
}

.switch-icon-columns {
  margin-right: 7px;
}

.icon-columns {
  margin: 0 15px;
}

.icon-columns img {
  height: 24px;
  width: 24px;
}

.label-upload-icons {
  background-color: #f7b84b;
  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  padding: 0 8px;
  cursor: pointer;
  transition: 0.2s;
}

.disableUploadIcon {
  cursor: no-drop;
  background-color: #b4b4b4;
}

.label-upload-icons:hover {
  opacity: 0.8;
}

.label-upload-icons img {
  margin-right: 7px;
}

.legend-options,
.title-opitons {
  border: thin dashed #dcdcdc;
  border-radius: 5px;
  padding: 5px 5px 10px 5px;
  margin-bottom: 20px;

  p {
    font-weight: 600;
  }

  &__items {
    display: flex;
  }

  &__item:nth-last-child(2) {
    margin: 0 30px;
  }

  .el-form-item {
    margin-bottom: 0px;
  }
}

.legend-options {
  &__item {
    width: 100%;
  }
}

.title-options__title {
  margin-bottom: 15px !important;
}

:deep .grid-options {
  border: thin dashed #dcdcdc;
  border-radius: 5px;
  padding: 5px 5px 10px 5px;
  margin-bottom: 20px;

  p {
    font-weight: 600;
  }

  &__input {
    margin-right: 10px;
  }

  &__items {
    display: flex;
  }

  &__item {
    width: 100%;

    .el-form-item {
      margin-bottom: 0 !important;
      font-weight: 500;
    }
  }
}

:deep .sort-default {
  display: flex;
}

:deep .select-icons .el-input {
  width: 100px !important;
}

.card-item {
  &__block {
    display: flex;
    width: calc(50% - 10px);
    float: left;
    border: thin solid #dcdcdc;
    margin: 5px;
    padding: 10px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(41, 156, 219, 0.1);
      // border-color: #409eff;
    }
  }

  &__status {
    position: absolute;
    top: 26%;
    left: 50%;
    transform: translate(-50%, 50%);
    text-align: center;
  }

  &__box {
    width: calc(100% - 70px);
    text-align: end;
  }

  &__title {
    color: #606266;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.active {
  background-color: rgba(41, 156, 219, 0.1);
  border-color: #409eff;
}

.table-link > .el-form-item__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.error {
  color: var(--el-color-danger);
  font-size: 12px;
}

:deep .error_value {
  display: flex;

  .el-textarea__inner,
  .el-input__wrapper {
    box-shadow: 0 0 0 1px #f56c6c inset;
  }
}

:deep .el-tag {
  .el-tag__content {
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

:deep .item-relative {
  position: relative;

  label {
    margin-left: 8px;
  }
}

.label-requied-item {
  color: #f56c6c;
  position: absolute;
  top: -5px;
  left: 0px;
}

.form-card-items {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.el-link.is-underline:hover:after {
  border-bottom: none;
}
</style>
